import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import blogBanner from '../images/blogBanner.png';
import MediaGrid from '../components/MediaGrid';

const Media = () => {
  return (
    <>
      <Layout isWithoutHero={true}>
        <SEO
          title='Media Presence'
          bannerImage={blogBanner}
          image={blogBanner}
        />
        <div
          style={{
            width: '100%',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ marginTop: '150px' }} className='handbook-heading'>
            Media Mentions
          </div>
          <div className='handbook-description' style={{ textAlign: 'center' }}>
            Nuclei getting recognised!
          </div>
        </div>
        <MediaGrid />
      </Layout>
    </>
  );
};

export default Media;
