// @ts-nocheck
import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

const MediaCard = props => (
  <a href={`${props.node.redirectionUrl}/`} target='_blank'>
    <div
      className='card blog-card-mediamatch'
      style={{
        borderRadius: '10px',
        height: '100%',
      }}
    >
      {' '}
      <div className='card-image'>
        {props.node.featuredImage && (
          <div className='card-image-container'>
            <center>
              <img
                className='banks-grid-img'
                style={{
                  backgroundColor: props.node.color,
                  height: '20vh',
                  width: '90%',
                }}
                src={props.node.featuredImage.file.url}
              />
            </center>
          </div>
        )}
        <div className='card-content'>
          <div className='media-content'>
            <p className='card-title is-4'> {props.node.shortDescription}</p>
          </div>
        </div>
      </div>
    </div>
  </a>
);

const MediaGrid = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulMediaPost(sort: { fields: [priority], order: ASC }) {
        edges {
          node {
            redirectionUrl
            featuredImage {
              file {
                url
              }
            }
            publishedDate
            shortDescription
            priority
          }
        }
      }
    }
  `);

  return (
    <section className='section'>
      <div className='columns is-multiline blog-grid-card'>
        {data.allContentfulMediaPost.edges &&
          data.allContentfulMediaPost.edges.map(edge => (
            <div className='column is-3-fullhd  is-3-desktop '>
              <MediaCard key={edge.node.id} node={edge.node} />
            </div>
          ))}
      </div>
    </section>
  );
};

export default MediaGrid;
